import React, {Component} from "react";
import {
    Spinner,
    Button,
    Form,
    Row,
    Col,
    ButtonGroup,
    ToggleButton,
    Accordion,
    useAccordionButton,
    Card
} from "react-bootstrap";
import '../../../App.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Redirect} from "react-router-dom";
import {AppContext} from "../../../AppContext";
import axios from "axios";
import Select from 'react-select'
import DbUser from "../../../queries/DbUser";
import data from "bootstrap/js/src/dom/data";


const defaultSorted = [{
    dataField: 'statusWeight',
    order: 'asc'
}];


class MachineExternalList extends Component {

    static contextType = AppContext;

    state = {
        filteredData: [],
        isDataLoaded: false,
        redirect: '',
        isFilterHidden: true,
        isFilterClear: true,
        searchInput: '',
        options: {
            custom: true,
            totalSize: data.length,
            page: this.context.machineListState.page,
            sizePerPage: this.context.machineListState.sizePerPage,
            dataSize: 0,
            paginationSize: 1
        }
    }

    componentDidMount() {

        DbUser.getLoggedUser(this.context)

        let item = {}
        let provinces = this.context.data.provinces.map(province => {
                item = {value: province, label: province}
                return item
            }
        )
        this.setState({...this.state, provinces})

        this.handleOnClickSearch()

        item = {}
        let statuses = this.context.data.deviceStatus.map(status => {
                item = {value: status.name, label: status.name}
                return item
            }
        )

        axios.get(`${this.context.hostname}/api/category/findAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                item = {}
                let categories = res.data.map(category => {
                        item = {value: category.name, label: category.name}
                        return item
                    }
                )
                this.setState({
                    categories,
                    statuses
                })
            }
        })
    }


    handleBackOnClick = () => {
        this.setState({redirect: '/home'})
    }

    toggleFilterHiddenOnClick = () => {
        let isFilterClear = false;
        if ((this.state.id === '' || this.state.id < 1)
            && this.context.machineListState.status.length < 1
            && this.context.machineListState.customer === ''
            && this.context.machineListState.category.length < 1
            && this.context.machineListState.serialNumber === ''
            && this.context.machineListState.sourcePower === ''
            && this.context.machineListState.dateFrom === ''
            && this.context.machineListState.dateTo === ''
            && this.context.machineListState.warranty === ''
            && this.context.machineListState.street === ''
            && this.context.machineListState.zipCode === ''
            && this.context.machineListState.city === ''
            && this.context.machineListState.province.length < 1
        ) {
            isFilterClear = true;
        }

        this.setState({...this.state, isFilterHidden: !this.state.isFilterHidden, isFilterClear})
    }

    handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.context.toggleMachineListState(
            {
                ...this.context.machineListState,
                [name]: value
            })
    }

    handleOnClickSearch = e => {
        let statuses = this.context.machineListState.status.map(s => s.value)
        let categories = this.context.machineListState.category.map(c => c.value)
        let provinces = this.context.machineListState.province.map(p => p.value)
        axios.post(`${this.context.hostname}/api/device-external/getByFilter`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                id: this.context.machineListState.id,
                statuses: statuses,
                customer: this.context.machineListState.customer,
                categories: categories,
                serialNumber: this.context.machineListState.serialNumber,
                sourcePower: this.context.machineListState.sourcePower,
                dateFrom: this.context.machineListState.dateFrom +
                    (this.context.machineListState.dateFrom === '' ? '' : 'T00:00:00'),
                dateTo: this.context.machineListState.dateTo +
                    (this.context.machineListState.dateTo === '' ? '' : 'T00:00:00'),
                warranty: this.context.machineListState.warranty,
                street: this.context.machineListState.street,
                zipCode: this.context.machineListState.zipCode,
                city: this.context.machineListState.city,
                provinces: provinces

            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                let devices = res.data.map(device => {
                        return {...device, warrantyJoin: <div>{device.warrantyLength} mies. <br/>{device.warranty}</div>}
                    }
                )
                let filteredData = devices.filter(data =>
                    (data.id + data.status + data.name +
                        data.customer.fullName + data.customer.shortName + data.city + data.streetAddress +
                        data.zipCode + data.province + data.warranty + data.warrantyLength + data.category.name + data.serialNumber
                    ).toUpperCase().includes(this.context.machineListState.searchInput.toUpperCase())
                )

                let dataSize = filteredData.length
                let paginationSize = this.getPaginationSize(dataSize, this.state.options.sizePerPage);
                let page = this.state.options.page;
                if (page < 1) {
                    page = 1
                }
                if (paginationSize < page) {
                    page = paginationSize
                }
                this.setState({
                    ...this.state,
                    filteredData,
                    isDataLoaded: true,
                    options: {
                        ...this.state.options,
                        paginationSize,
                        page
                    }
                })
                this.context.toggleMachineListState({
                    ...this.context.machineListState,
                    paginationSize,
                    page,
                    data: devices
                })
            }
        })
            .catch((error) => {
                // console.log({error})
            });
    }

     handleOnClickDownloadCsv = e => {
         let statuses = this.context.machineListState.status.map(s => s.value)
         let categories = this.context.machineListState.category.map(c => c.value)
         let provinces = this.context.machineListState.province.map(p => p.value)
         axios.post(`${this.context.hostname}/api/device/get-csv-by-filter`, {
                 headers: {
                     "X-Requested-With": "XMLHttpRequest",
                     "Content-Type": "application/json",
                 },


                 id: this.context.machineListState.id,
                 statuses: statuses,
                 customer: this.context.machineListState.customer,
                 categories: categories,
                 serialNumber: this.context.machineListState.serialNumber,
                 sourcePower: this.context.machineListState.sourcePower,
                 dateFrom: this.context.machineListState.dateFrom +
                     (this.context.machineListState.dateFrom === '' ? '' : 'T00:00:00'),
                 dateTo: this.context.machineListState.dateTo +
                     (this.context.machineListState.dateTo === '' ? '' : 'T00:00:00'),
                 warranty: this.context.machineListState.warranty,
                 street: this.context.machineListState.street,
                 zipCode: this.context.machineListState.zipCode,
                 city: this.context.machineListState.city,
                 provinces: provinces

             },
             {withCredentials: true,     responseType: 'blob'}
         ).then(res => {
             if (res.status === 200) {
                 var url = window.URL.createObjectURL(res.data);
                 var a = document.createElement('a');
                 a.href = url;
                 a.download = ("maszyny.csv");
                 document.body.appendChild(a);
                 a.click();
                 a.remove();
                 // let devices = res.data.map(device => {
                 //         return {...device, warrantyJoin: <div>{device.warrantyLength} mies. <br/>{device.warranty}</div>}
                 //     }
                 // )
                 // let filteredData = devices.filter(data =>
                 //     (data.id + data.status + data.name +
                 //         data.customer.fullName + data.customer.shortName + data.city + data.streetAddress +
                 //         data.zipCode + data.province + data.warranty + data.warrantyLength + data.category.name + data.serialNumber
                 //     ).toUpperCase().includes(this.context.machineListState.searchInput.toUpperCase())
                 // )
                 //
                 // let dataSize = filteredData.length
                 // let paginationSize = this.getPaginationSize(dataSize, this.state.options.sizePerPage);
                 // let page = this.state.options.page;
                 // if (page < 1) {
                 //     page = 1
                 // }
                 // if (paginationSize < page) {
                 //     page = paginationSize
                 // }
                 // this.setState({
                 //     ...this.state,
                 //     filteredData,
                 //     isDataLoaded: true,
                 //     options: {
                 //         ...this.state.options,
                 //         paginationSize,
                 //         page
                 //     }
                 // })
                 // this.context.toggleMachineListState({
                 //     ...this.context.machineListState,
                 //     paginationSize,
                 //     page,
                 //     data: devices
                 // })
             }
         })
             .catch((error) => {
                 // console.log({error})
             });
    }

    onChangeStatusSelect = (e, data) => {
        const action = data.action
        if (action === 'select-option') {
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: [...this.context.machineListState.status, data.option]
                })
        }
        if (action === 'remove-value') {
            let toRemove = data.removedValue;
            let afterRemoved = this.context.machineListState.status.filter(status => status !== toRemove)
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: afterRemoved
                })
        }
        if (action === 'clear') {
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: []
                })
        }
    }

    onChangeCategorySelect = (e, data) => {
        const action = data.action
        if (action === 'select-option') {
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: [...this.context.machineListState.category, data.option]
                })
        }
        if (action === 'remove-value') {
            let toRemove = data.removedValue;
            let afterRemoved = this.context.machineListState.category.filter(category => category !== toRemove)
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: afterRemoved
                })
        }
        if (action === 'clear') {
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: []
                })
        }
    }

    onChangeProvinceSelect = (e, data) => {
        const action = data.action
        if (action === 'select-option') {
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState,
                    [data.name]: [...this.context.machineListState.province, data.option]
                })
        }
        if (action === 'remove-value') {
            let toRemove = data.removedValue;
            let afterRemoved = this.context.machineListState.province.filter(province => province !== toRemove)
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState, [data.name]: afterRemoved
                })
        }
        if (action === 'clear') {
            this.context.toggleMachineListState(
                {
                    ...this.context.machineListState, [data.name]: []
                })
        }
    }

    //-------PAGINATION-------
    handleNextPage = ({page, onPageChange}) => () => {
        this.saveMachineListPaginationState(page + 1);
        onPageChange(page + 1);
    }
    handlePrevPage = ({page, onPageChange}) => () => {
        this.saveMachineListPaginationState(page - 1);
        onPageChange(page - 1);
    }
    handleFirstPage = ({page, onPageChange}) => () => {
        this.saveMachineListPaginationState(1);
        onPageChange(1);
    }
    handleLastPage = ({page, onPageChange}) => () => {
        this.saveMachineListPaginationState(this.state.options.paginationSize)
        onPageChange(this.state.options.paginationSize);
    }
    handlePage = ({page, onPageChange}) => (e) => {
        const number = parseInt(e.target.id)
        this.saveMachineListPaginationState(number)
        onPageChange(number)
    }
    handleSizePerPage = ({page, onSizePerPageChange}, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
        const paginationSize = this.getPaginationSize(this.state.filteredData.length, newSizePerPage)
        if (page < 1) {
            page = 1
        }

        if (paginationSize < page) {
            page = paginationSize
        }

        this.context.toggleMachineListState(
            {
                ...this.context.machineListState,
                sizePerPage: newSizePerPage,
                paginationSize,
                page
            })
        this.setState({
            ...this.state,
            options: {...this.state.options, sizePerPage: newSizePerPage, paginationSize, page}
        })
    }

    saveMachineListPaginationState(number) {
        this.context.toggleMachineListState(
            {
                ...this.context.machineListState, page: number
            })
        this.setState({...this.state, options: {...this.state.options, page: number}})
    }

    getPaginationSize(dataSize, sizePerPage) {
        let modulo = dataSize % sizePerPage
        let fullPage = dataSize / sizePerPage
        let paginationSize = Math.floor(modulo > 0 ? fullPage + 1 : fullPage)
        return paginationSize;
    }

    //Szybka wyszukiwarka
    handleOnChangeSearchInput = (e) => {

        let filteredData = this.context.machineListState.data.filter(data =>
            (data.id + data.status + data.name +
                data.customer.fullName + data.customer.shortName + data.city + data.streetAddress +
                data.zipCode + data.province + data.warranty + data.warrantyLength + data.category.name + data.serialNumber
            ).toUpperCase().includes(e.target.value.toUpperCase())
        )
        let page = this.state.options.page
        if (page < 1) {
            page = 1
        }
        const paginationSize = this.getPaginationSize(filteredData.length, this.state.options.sizePerPage)

        if (paginationSize < page) {
            page = paginationSize
        }

        this.context.toggleMachineListState(
            {
                ...this.context.machineListState, searchInput: e.target.value,
                paginationSize, page
            })
        this.setState({
            ...this.state,
            searchInput: e.target.value,
            filteredData,
            options: {
                ...this.state.options,
                paginationSize, page
            }
        })
    }

    render() {
        const onClickEvent = {

            onClick: (e, column, columnIndex, row, rowIndex) => {
                const link = `/machineExternalEdit?id=${row.id}&customerId=${row.customer.id}&prev=machineExternalList`
                this.setState({redirect: link})
            }
        }

        const cellStyle = {fontSize: '11.5px', padding: 5, verticalAlign: 'middle', height: '60px'}

        const columns = [
            {
                dataField: 'id',
                text: 'id',
                headerAlign: 'center',
                sort: true,
                events: onClickEvent,
                style: {...cellStyle, minWidth: '60px', textAlign: 'center'}

            },
            {
                dataField: 'status',
                headerAlign: 'center',
                align: 'center',
                text: 'Status',
                sort: true,
                events: onClickEvent,
                style: {width: '7%', ...cellStyle,},
                filterValue: cell => `${cell}`,

                formatter: statusFormatter,
                formatExtraData: {
                    context: this.context,
                }
            },
            {
                dataField: 'statusWeight',
                headerAlign: 'center',
                text: 'Status',
                sort: true,
                events: onClickEvent,
                width: 200,
                filterValue: cell => `${cell}`,
                formatter: cell => `${cell}`,
                hidden: true,
            },
            {
                dataField: 'name',
                align: 'center',
                headerAlign: 'center',
                text: 'Nazwa',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '140px', ...cellStyle,}
            }, {
                dataField: 'customer.shortName',
                headerAlign: 'center',
                align: 'center',
                text: 'Kontrahent',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '160px', ...cellStyle,}
            }, {
                dataField: 'customer.id',
                text: 'id kontrahenta',
                sort: true,
                events: onClickEvent,
                hidden: true
            }, {
                dataField: 'category.name',
                align: 'center',
                headerAlign: 'center',
                text: 'Kategoria',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '160px', ...cellStyle,}
            }, {
                dataField: 'serialNumber',
                headerAlign: 'center',
                text: 'S/N',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '120px', textAlign: 'center', ...cellStyle,}
            }, {
                dataField: 'sourcePower',
                headerAlign: 'center',
                text: 'Moc',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '110px', ...cellStyle, textAlign: 'center'}
            }, {
                dataField: 'transferDate',
                headerAlign: 'center',
                text: 'Data',
                sort: true,
                events: onClickEvent,
                formatter: cell => `${cell != null ? cell.substring(0, 10) : false}`,
                style: {minWidth: '100px', ...cellStyle, textAlign: 'center'}
            }, {
                dataField: 'warrantyJoin',
                align: 'center',
                headerAlign: 'center',
                text: 'Gwarancja',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '120px', ...cellStyle,}
            }, {
                dataField: 'streetAddress',
                headerAlign: 'center',
                align: 'center',
                text: 'Ulica',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '140px', ...cellStyle,}
            }, {
                dataField: 'zipCode',
                align: 'center',
                headerAlign: 'center',
                text: 'Kod',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '60px', ...cellStyle,}
            }, {
                dataField: 'city',
                align: 'center',
                headerAlign: 'center',
                text: 'Miasto',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '60px', ...cellStyle,}
            }, {
                dataField: 'province',
                headerAlign: 'center',
                align: 'center',
                text: 'Województwo',
                sort: true,
                events: onClickEvent,
                style: {minWidth: '140px', ...cellStyle,}
            }];

        function statusFormatter(cell, row, rowIndex, formatExtraData) {

            const statuses = formatExtraData.context.data.deviceStatus;

            let statusDiv;
            statuses.map(s => {
                if (s.name === cell) {
                    const style = {
                        backgroundColor: s.colorBackground,
                        width: 100,
                        color: s.colorText,
                        textAlign: 'center',
                        padding: '5px',
                        borderRadius: '5px',
                        margin: '0 auto'
                    }
                    const bc = s.backgroundColor
                    statusDiv = <div  style={style} size="sm">{s.name}</div>
                }
                ;
            })
            return (
                statusDiv
            );
        }

        const style = {minWidth: 170, maxWidth: 190, paddingLeft: 5, paddingRight: 5}


        function ToggleSearchOptions({children, eventKey}) {
            const decoratedOnClick = useAccordionButton(eventKey, () =>
                console.log(''),
            );


            return (
                <Button
                    type="button"
                    variant="light"
                    style={{backgroundColor: 'white'}}
                    onClick={decoratedOnClick}
                >
                    {children}
                </Button>
            );
        }

        return (

            <div className="fragment">
                {this.state.redirect !== '' ? <Redirect to={this.state.redirect}/> : ''}
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <ToolkitProvider
                    bootstrap3
                    keyField="id"
                    data={this.state.filteredData}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    noDataIndication=""
                    search>
                    {
                        props => (
                            <div>

                                <h3><Button variant="light" onClick={this.handleBackOnClick}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                         className="bi bi-arrow-return-left"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                </Button>
                                    &nbsp;Lista maszyn zewnętrznych
                                    <Button
                                        className="float-end"
                                        variant="outline-dark"
                                        onClick={() => this.setState({redirect: '/machineAdd'})}
                                    >+</Button>
                                </h3>

                                <Accordion>
                                    <Card>
                                        <Card.Header style={{backgroundColor: 'white'}}>
                                            <Row>
                                                <Col style={{maxWidth: 600, padding: 0, paddingRight: 3}}>
                                                    <Form.Control
                                                        value={this.context.machineListState.searchInput}
                                                        onChange={this.handleOnChangeSearchInput}
                                                        placeholder="...wpisz aby wyszukać"
                                                    />

                                                </Col>
                                                <Col style={{padding: 0, maxWidth: 40}}>
                                                    <ToggleSearchOptions eventKey="0">
                                                        {((this.context.machineListState.id === '' || this.context.machineListState.id < 1)
                                                            && this.context.machineListState.status.length < 1
                                                            && this.context.machineListState.customer === ''
                                                            && this.context.machineListState.category.length < 1
                                                            && this.context.machineListState.serialNumber === ''
                                                            && this.context.machineListState.sourcePower === ''
                                                            && this.context.machineListState.dateFrom === ''
                                                            && this.context.machineListState.dateTo === ''
                                                            && this.context.machineListState.warranty === ''
                                                            && this.context.machineListState.street === ''
                                                            && this.context.machineListState.zipCode === ''
                                                            && this.context.machineListState.city === ''
                                                            && this.context.machineListState.province.length < 1
                                                        )
                                                            ? <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                   height="16"
                                                                   fill="currentColor" className="bi bi-chevron-down"
                                                                   viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>

                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16"
                                                                 fill="red" className="bi bi-chevron-double-down"
                                                                 viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                      d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                <path fill-rule="evenodd"
                                                                      d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                            </svg>}


                                                    </ToggleSearchOptions>
                                                </Col>
                                                <Col>

                                                </Col>

                                            </Row>


                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Form>

                                                    <Row style={{paddingLeft: 10, paddingRight: 10}}>

                                                        <Col style={{...style}}>
                                                            <Form.Label>id</Form.Label>
                                                            <Form.Control type='number'
                                                                          value={this.context.machineListState.id}
                                                                          name='id'
                                                                          onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col style={{...style, maxWidth: 400, minWidth: 300}}>
                                                            <Form.Label>status</Form.Label>
                                                            <Select
                                                                options={this.state.statuses}
                                                                value={this.context.machineListState.status}
                                                                onChange={this.onChangeStatusSelect}
                                                                isMulti
                                                                name='status'
                                                                closeMenuOnSelect={false}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>kontrahent</Form.Label>
                                                            <Form.Control value={this.context.machineListState.customer}
                                                                          name='customer'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={{...style, maxWidth: 400, minWidth: 300}}>
                                                            <Form.Label>kategoria</Form.Label>
                                                            <Select
                                                                options={this.state.categories}
                                                                value={this.context.machineListState.category}
                                                                onChange={this.onChangeCategorySelect}
                                                                isMulti
                                                                name='category'
                                                                closeMenuOnSelect={false}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>s/n</Form.Label>
                                                            <Form.Control
                                                                value={this.context.machineListState.serialNumber}
                                                                name='serialNumber'
                                                                onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>moc</Form.Label>
                                                            <Form.Control
                                                                value={this.context.machineListState.sourcePower}
                                                                name='sourcePower'
                                                                onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={{...style, minWidth: 150}}>
                                                            <Form.Label>data od</Form.Label>
                                                            <Form.Control type='date'
                                                                          value={this.context.machineListState.dateFrom}
                                                                          name='dateFrom'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={{...style, minWidth: 150}}>
                                                            <Form.Label>data do</Form.Label>
                                                            <Form.Control type='date'
                                                                          value={this.context.machineListState.dateTo}
                                                                          name='dateTo'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>gwarancja</Form.Label>
                                                            <Form.Control value={this.context.machineListState.warranty}
                                                                          name='warranty'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>ulica</Form.Label>
                                                            <Form.Control value={this.context.machineListState.street}
                                                                          name='street'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>kod pocztowy</Form.Label>
                                                            <Form.Control value={this.context.machineListState.zipCode}
                                                                          name='zipCode'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={style}>
                                                            <Form.Label>miasto</Form.Label>
                                                            <Form.Control value={this.context.machineListState.city}
                                                                          name='city'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                        <Col style={{...style, maxWidth: 400, minWidth: 300}}>
                                                            <Form.Label>województwo</Form.Label>
                                                            <Select
                                                                options={this.state.provinces}
                                                                value={this.context.machineListState.province}
                                                                onChange={this.onChangeProvinceSelect}
                                                                isMulti
                                                                name='province'
                                                                closeMenuOnSelect={false}/>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{paddingTop: 15}}>
                                                        <Col>
                                                            <Button className="float-end" size="sm"
                                                                    onClick={this.handleOnClickSearch}>Szukaj</Button>

                                                            <Button className="float-end" size="sm" style={{marginRight: 10}}
                                                                    onClick={this.handleOnClickDownloadCsv}>Pobierz CSV</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <hr/>
                                {this.state.isDataLoaded ?

                                    <PaginationProvider
                                        pagination={paginationFactory(this.state.options)}
                                    >
                                        {
                                            ({
                                                 paginationProps,
                                                 paginationTableProps
                                             }) => (
                                                <div>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        bordered={false}
                                                        data={this.state.filteredData}
                                                        columns={columns}
                                                        {...paginationTableProps}
                                                    />
                                                    {/*PAGINATION*/}
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <ButtonGroup className="mb-2">
                                                                    {this.context.paginationProperties.sizePerPageList.map((value) =>
                                                                        <ToggleButton
                                                                            variant={value == this.state.options.sizePerPage ? 'secondary' : 'warning'}
                                                                            onClick={() =>
                                                                                this.handleSizePerPage(paginationProps, value)
                                                                            }
                                                                        >
                                                                            {value}</ToggleButton>
                                                                    )}

                                                                </ButtonGroup>
                                                            </Col>
                                                            <Col>
                                                                <ButtonGroup className="mb-2 float-end">
                                                                    <Button variant="outline-primary"
                                                                            disabled={this.state.options.page <= 1}
                                                                            onClick={this.handleFirstPage(paginationProps)}>{'<<'}
                                                                    </Button>
                                                                    <Button variant="outline-primary"
                                                                            disabled={this.state.options.page <= 1}

                                                                            onClick={this.handlePrevPage(paginationProps)}>{'<'}
                                                                    </Button>
                                                                    {this.state.options.page >= 3 ?
                                                                        <Button id={paginationProps.page - 2}
                                                                                variant="outline-primary"
                                                                                onClick={this.handlePage(paginationProps)}>{paginationProps.page - 2}</Button> : false}
                                                                    {this.state.options.page >= 2 ?
                                                                        <Button id={paginationProps.page - 1}
                                                                                variant="outline-primary"
                                                                                onClick={this.handlePage(paginationProps)}>{paginationProps.page - 1}</Button> : false}
                                                                    <Button id={paginationProps.page} variant="primary"
                                                                            onClick={this.handlePage(paginationProps)}>{paginationProps.page}</Button>
                                                                    {this.state.options.paginationSize > this.state.options.page ?
                                                                        <Button id={paginationProps.page + 1}
                                                                                variant="outline-primary"
                                                                                onClick={this.handlePage(paginationProps)}>{paginationProps.page + 1}</Button> : false}
                                                                    {this.state.options.paginationSize > this.state.options.page + 1 ?
                                                                        <Button id={paginationProps.page + 2}
                                                                                variant="outline-primary"
                                                                                onClick={this.handlePage(paginationProps)}>{paginationProps.page + 2}</Button> : false}
                                                                    <Button variant="outline-primary"
                                                                            disabled={this.state.options.page >= this.state.options.paginationSize}
                                                                            onClick={this.handleNextPage(paginationProps)}>{'>'}
                                                                    </Button>
                                                                    <Button variant="outline-primary"
                                                                            disabled={this.state.options.page >= this.state.options.paginationSize}
                                                                            onClick={this.handleLastPage(paginationProps)}>{'>>'}
                                                                    </Button>

                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {/*--------------------*/}
                                                </div>
                                            )
                                        }
                                    </PaginationProvider>
                                    : false}
                            </div>
                        )
                    }
                </ToolkitProvider>
                {!this.state.isDataLoaded &&
                <div className={"container"} style={{textAlign: "center"}}><Spinner animation="border"/></div>}
            </div>
        )
    }
}

export default MachineExternalList;
