import React, {Component} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'
import {AppContext} from "../AppContext";
import {Link, Redirect} from 'react-router-dom';
import axios from "axios";
import {Container} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";


class NavigationBar extends Component {

    static contextType = AppContext;

    state = {
        redirectLink: '',
        redirect: false
    }

    handleLoginOnClick = () => {

        if (this.context.isUserLogged === true) {
            axios.get(`${this.context.hostname}/logout`, {
                headers: {"X-Requested-With": "XMLHttpRequest"},
                withCredentials: true,
            }).then(res => {
                if (res.status = 200) {
                    window.location.href = `/`;
                }
            })
                .catch((error) => {
                    console.log(error)
                    window.location.href = `/`;
                });
        }
        this.setState({redirectLink: '/login'})
    }


    render() {
        return (
            <div>
                {this.state.redirectLink !== '' ? <Redirect to={this.state.redirectLink}/> : ''}
                <Navbar bg="light" expand="lg" fixed={"top"} style={{paddingLeft: 30, paddingRight: 30}}>
                        <Navbar.Brand as={Link} to='/home'>
                            <img
                                src="logo.png"
                                width="55"
                                height="55"
                                className="d-inline-block align-top"
                                alt="Maszyny Polskie"
                            />
                        </Navbar.Brand>
                        <Navbar.Brand as={Link} to='/home'>SERWIS</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {this.context.isUserLogged ?
                            <Nav className="mr-auto">
                                <NavDropdown title="Maszyny" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/machineAdd">Dodaj</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/machineList">Lista maszyn</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/servicesList">Lista zdarzeń serwisowych</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/machinePeriodicInspection">Przeglądy</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/machineTodo">Do wykonania</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/map">Mapa</NavDropdown.Item>
                                </NavDropdown>


                                <NavDropdown title="Maszyny Zewnętrzne" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/machineExternalAdd">Dodaj</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/machineExternalList">Lista maszyn</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/servicesExternalList">Lista zdarzeń serwisowych</NavDropdown.Item>
                                </NavDropdown>


                                {/*<NavDropdown title="Zdarzenia serwisowe" id="basic-nav-dropdown">*/}
                                {/*    <NavDropdown.Item as={Link} to="/servicesList">Lista zdarzeń serwisowych</NavDropdown.Item>*/}
                                {/*</NavDropdown>*/}
                                <NavDropdown title="Kontrahenci" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="customerAdd">Dodaj</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="customerList">Lista kontrahentów</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Kategorie" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="categoryAdd">Dodaj</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="categoryList">Lista kategorii</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link as={Link} to="/tutorials">Tutoriale</Nav.Link>
                                {this.context.isAdmin === true ?<>
                                    <NavDropdown title="Pracownicy" id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="userAdd">Dodaj</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="userList">Lista pracowników</NavDropdown.Item>
                                    </NavDropdown>

                                    <Nav.Link as={Link} to="/statistics">Statystyki</Nav.Link>


                            </> : false}
                                {this.context.isAdmin === true ?
                                    <>
                                    <Nav.Link as={Link} to="/logs">Logi</Nav.Link></> : false}
                            </Nav>
                            : ''}

                    </Navbar.Collapse>
                        <Navbar.Collapse className="justify-content-end">
                        <div className='nav text-secondary mr-lg-auto' style={{fontSize: '10px', textAlign: 'center'}}>
                            {this.context.isUserLogged ? `Jesteś zalogowany jako:` : ''}<br/>
                            {this.context.isUserLogged ? `${this.context.firstName} ${this.context.lastName}` : ''}
                            {this.context.isAdmin ? ' (admin)' : ''}

                            <br/>
                            {this.context.isUserLogged ? `(${this.context.login})` : ''}
                        </div>
                        </Navbar.Collapse>
                        <Navbar.Collapse className="justify-content-end">
                        {this.context.isUserLogged ? <Nav.Link as={Link} to="/myAccount"><i className="bi bi-gear" style={{fontSize: 22}}></i></Nav.Link> : false}
                        <Nav.Link onClick={this.handleLoginOnClick} className="justify-content-end"
                                  style={{color: 'grey'}}>{this.context.isUserLogged ? 'Wyloguj' : 'Zaloguj'}</Nav.Link>
                        </Navbar.Collapse>
                    {/*</Container>*/}
                </Navbar>
            </div>
        );
    }
}

export default NavigationBar;
