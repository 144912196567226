import React, {Component} from "react";
import {
    Alert,
    Button, Card,
    Dropdown,
    Form,
    Modal
} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import '../../../App.css';
import './MachineExternalEdit.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {DropdownButton} from "react-bootstrap/DropdownButton";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {AppContext} from "../../../AppContext";
import MachineFiles from "./../MachineFiles";
import MachineParameters from "./../MachineParameters";
import MachineService from "./../MachineService";
import MachineNotes from "./../MachineNotes";
import MachineEquipments from "./../MachineEquipments";
import axios from "axios";
import MachineHotels from "./../MachineHotels";
import DbHotel from "../../../queries/DbHotel";
import MachinePeriodicInspection from "./../MachinePeriodicInspection";
import LayoutUtil from "../../../utils/LayoutUtil";
import MachineMap from "./../MachineMap";
import MachineIncident from "./../MachineIncident";
import MachineNamePlates from "./../MachineNamePlates";
import DbUser from "../../../queries/DbUser";


class MachineExternalEdit extends Component {

    DEFAULT_TAB = 'nameplates'

    static contextType = AppContext;

    state = {
        id: new URLSearchParams(this.props.location.search).get("id"),
        prev: new URLSearchParams(this.props.location.search).get("prev"),
        activeTab:  new URLSearchParams(this.props.location.search).get("activeTab") !== null ?
            new URLSearchParams(this.props.location.search).get("activeTab") : this.DEFAULT_TAB,
        categoryId: '',
        customerId: new URLSearchParams(this.props.location.search).get("customerId"),
        machine: {
            id: new URLSearchParams(this.props.location.search).get("id"),
            name: '',
            category: '',
            customer: {id: new URLSearchParams(this.props.location.search).get("customerId"),},
            serialNumber: '',
            sourcePower: '',
            transferDate: '',
            warranty: '',
            warrantyLength: '',
            streetAddress: '',
            zipCode: '',
            city: '',
            status: '',
            // parts: [],
            gpsLatitude: 0,
            gpsLongitude: 0
        },
        customers: [],
        categories: [],
        services: [],
        hotels: [],
        devicesNames: [],
        exit: false,
        delete: false,
        showDeleteModal: false,
        redirect: '',
        isNewDeviceTextFieldActive: false,
        styleDropdown: {width: 120, backgroundColor: 'white', borderColor: 'white'},
        validatorMessage: ''
    }


    constructor(props) {
        super(props);
        this.map = React.createRef();
        this.hotels = React.createRef();
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/device/findById/${this.state.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {

                let style = LayoutUtil.getStatusStyle(this.context, res.data.status);

                this.setState({
                    customerId: res.data.customer.id,
                    categoryId: res.data.category.id,
                    machine: {
                        ...res.data,
                        category: res.data.category.name,
                        customer: res.data.customer,
                    },
                    isDataLoaded: true,
                    styleDropdown: style
                })


            }
        })
            .catch((error) => {
                console.log(error)
            });

        axios.get(`${this.context.hostname}/api/device/findAllDevicesNames`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    devicesNames: res.data,
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });

        axios.get(`${this.context.hostname}/api/customer/findAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    customers: res.data,
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });


        axios.get(`${this.context.hostname}/api/category/findAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                let categories = []
                categories.push('')
                let data = []
                data = res.data
                data.forEach(value => categories.push(value))


                this.setState({
                    categories: categories,
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });

        DbHotel.getHotelsAfterDeviceId(this.state.id, this.context)
    }


    handleChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;
        let categoryId = this.state.categoryId;
        let customerId = this.state.customerId;
        if (name === 'category') {

            categoryId = e.target.children[e.target.selectedIndex].id;
        }
        if (name == 'customer') {

            customerId = e.target.children[e.target.selectedIndex].id;
        }
        if (name === 'warrantyLength' && value < 0) {
            value = '';
        }
        this.setState(prevState => (
            {
                categoryId: categoryId,
                customerId: customerId,

                machine: {
                    ...prevState.machine,
                    [name]: value,
                }
            }
        ))
    }

    handleChangeStatus = (e) => {

        let style = LayoutUtil.getStatusStyle(this.context, e.target.name);
        this.setState({
            ...this.state,
            machine: {
                ...this.state.machine,
                status: e.target.name
            },
            styleDropdown: style
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        const value = e.target.name.value;
        if (value.length < 3) {
            this.setState({
                validatorMessage: 'Nazwa musi mieć minimum 3 znaki'
            })
        } else {
            this.submitToAPI(
                this.state.machine.id,
                this.state.machine.name,
                this.state.categoryId,
                this.state.customerId,
                this.state.machine.serialNumber,
                this.state.machine.sourcePower,
                this.state.machine.transferDate,
                this.state.machine.warrantyLength,
                this.state.machine.warranty,
                this.state.machine.streetAddress,
                this.state.machine.zipCode,
                this.state.machine.city,
                this.state.machine.province,
                this.state.machine.status)
            this.setState({
                validatorMessage: ''
            })
        }
    }

    submitToAPI = (id, name, category, customer, serialNumber, sourcePower, transferDate, warrantyLength, warranty, streetAddress, zipCode, city, province, status) => {

        axios.put(`${this.context.hostname}/api/device-external/update`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": id,
                "name": name,
                "category": {id: category},
                "customer": {id: customer},
                "serialNumber": serialNumber,
                "sourcePower": sourcePower,
                "transferDate": transferDate,
                "warranty": warranty,
                "warrantyLength": warrantyLength,
                "streetAddress": streetAddress,
                "zipCode": zipCode,
                "city": city,
                "province": province,
                "status": status,
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({validatorMessage: "Błąd!"})
            });
    }

    handleOnClickDelete = () => {
        this.setState({
            showDeleteModal: true
        })
    }

    handleDeleteOnClick = () => {
        this.setState({
            delete: true,
            showDeleteModal: false,
        });

        axios.delete(`${this.context.hostname}/api/device/delete/${this.state.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    validatorMessage = 'Brak uprawnień do tej operacji.'
                }  else if (error.response.status === 409) {
                    validatorMessage = 'Operacja niedozwolona. Aby usunąć maszynę trzeba usunąć wszystkie obiekty które ją wykorzystują.'
                }
                this.setState({...this.state, validatorMessage: validatorMessage})
            });
    }

    handleBackOnClick = () => {
        this.setState({redirect: `/${this.state.prev}`})
    }

    handleSelect = (key) => {
        if (key == 'Map') {
            this.map.current.showMap();
        } else  if (key =='hotels') {
            this.hotels.current.showMap();
        }
    }


    render() {

        if (this.state.exit === true) {
            return (<Redirect to={this.state.prev}/>
            )
        }

        return (

            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <Row>
                    <Col sm={3}>
                        <Card style={{height: '100%'}}>
                            <Card.Body>
                        <h3><Button variant="light" onClick={this.handleBackOnClick}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                        </Button>
                            &nbsp;Szczegóły
                                <Button onClick={this.handleOnClickDelete}
                                        size="sm"
                                        variant="outline-dark"
                                        className="float-end"
                                        style={{marginLeft: "5px", marginBottom: "5px"}}
                                >
                                    Usuń maszynę
                                </Button>
                        </h3>

                        <Form.Label>Status
                        </Form.Label>
                        <Dropdown style={{width: 100}}>

                            <Dropdown.Toggle style={this.state.styleDropdown} id="dropdown-basic">
                                {this.state.machine.status}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {this.context.data.deviceStatus.map(status =>
                                    <Dropdown.Item onClick={this.handleChangeStatus}
                                                   name={status.name}>{status.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>


                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicEmail">

                                <Form.Label>Nazwa (id: {this.state.id})

                                </Form.Label>

                                <Row>
                                    <Col>
                                        {!this.state.isNewDeviceTextFieldActive ?
                                            <Form.Control as="select" name="name"
                                                          value={this.state.machine.name}
                                                          onChange={this.handleChange}>
                                                {this.state.devicesNames.map(name =>
                                                    <option id={name}>{name}</option>
                                                )}
                                            </Form.Control> :
                                            <Form.Control
                                                type="text" name="name"
                                                value={this.state.machine.name}
                                                placeholder="Wpisz nową nazwę maszyny..."
                                                onChange={this.handleChange}
                                            />
                                        }
                                    </Col>
                                    <Col xs={2}>
                                        {!this.state.isNewDeviceTextFieldActive ?
                                            <Button style={{marginLeft: 16, width: 40}}
                                                    className="float-end pull-right"
                                                    variant="outline-dark"
                                                    onClick={() => this.setState({
                                                        ...this.state,
                                                        isNewDeviceTextFieldActive: !this.state.isNewDeviceTextFieldActive,
                                                        data: {
                                                            ...this.state.data,
                                                            machine: {...this.state.machine, name: ''}
                                                        }
                                                    })}
                                            >+</Button> :
                                            <Button style={{marginLeft: 16, width: 40}} className="float-end"
                                                    variant="outline-dark" onClick={() => this.setState({
                                                ...this.state,
                                                isNewDeviceTextFieldActive: !this.state.isNewDeviceTextFieldActive,
                                            })}>
                                                ☰
                                            </Button>}
                                    </Col>
                                </Row>

                                <Form.Label>Kategoria
                                </Form.Label>
                                <Form.Control as="select" name="category"
                                              value={this.state.machine.category}
                                              onChange={this.handleChange}

                                >
                                    {this.state.categories.map(category =>
                                        <option id={category.id}>{category.name}</option>
                                    )}
                                </Form.Control>
                                <Form.Label>Kontrahent
                                </Form.Label>
                                <Form.Control as="select" name="customer"
                                              value={this.state.machine.customer.shortName}
                                              onChange={this.handleChange}>
                                    {this.state.customers.map(customer =>
                                        <option id={customer.id}>{customer.shortName}
                                        </option>
                                    )}
                                </Form.Control>
                                <Form.Label>Numer seryjny
                                </Form.Label>
                                <Form.Control
                                    type="text" name="serialNumber"
                                    value={this.state.machine.serialNumber}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Moc źródła
                                </Form.Label>
                                <Form.Control
                                    type="text" name="sourcePower"
                                    value={this.state.machine.sourcePower}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Data przekazania
                                </Form.Label>
                                <Form.Control
                                    type="date" name="transferDate"
                                    value={this.state.machine.transferDate}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />

                                <Row className="g-2">
                                    <Col md>
                                        <Form.Label>Gwarancja [miesiące]
                                        </Form.Label>
                                        <Form.Control
                                            type="number" name="warrantyLength"
                                            value={this.state.machine.warrantyLength}
                                            placeholder="Wpisz..."
                                            onChange={this.handleChange}
                                        />
                                    </Col>

                                    <Col md>
                                        <Form.Label>Rodzaj gwarancji
                                        </Form.Label>
                                        <Form.Control
                                            type="text" name="warranty"
                                            value={this.state.machine.warranty}
                                            placeholder="Wpisz..."
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                                <Form.Label>Ulica
                                </Form.Label>
                                <Form.Control
                                    type="text" name="streetAddress"
                                    value={this.state.machine.streetAddress}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Kod pocztowy
                                </Form.Label>
                                <Form.Control
                                    type="text" name="zipCode"
                                    value={this.state.machine.zipCode}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Miasto
                                </Form.Label>
                                <Form.Control
                                    type="text" name="city"
                                    value={this.state.machine.city}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Województwo
                                </Form.Label>
                                <Form.Control as="select" name="province" style={{marginBottom: 10}}
                                              value={this.state.machine.province}
                                              onChange={this.handleChange}>
                                    {this.context.data.provinces.map(province =>
                                        <option id={province}>{province}</option>
                                    )}
                                </Form.Control>

                                {this.state.validatorMessage != '' ?
                                    <Alert variant='danger'>
                                        {this.state.validatorMessage}
                                    </Alert> : false
                                }

                            </Form.Group>
                            <Button
                                variant="outline-dark"
                                type="submit"
                                size="sm"
                                style={{marginRight: "5px", marginTop: 10}}>
                                Zapisz
                            </Button>
                            <Button
                                style={{marginTop: 10}}
                                size="sm"
                                variant="outline-dark"
                                onClick={() => this.setState({redirect: `/${this.state.prev}`})}>
                                Anuluj
                            </Button>
                        </Form>

                        <Modal show={this.state.showDeleteModal} onHide={() => this.setState({showDeleteModal: false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Potwierdzenie</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Czy napewno chcesz usunąć: {this.state.shortName}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({showDeleteModal: false})}>
                                    Anuluj
                                </Button>
                                <Button variant="primary" onClick={this.handleDeleteOnClick}>
                                    Usuń
                                </Button>
                            </Modal.Footer>
                        </Modal>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col sm={9}>
                        <Card style={{height: '100%'}}>
                            <Card.Body>
                        <Tabs variant="tabs" style={{maxWidth: '100%'}} defaultActiveKey={this.state.activeTab} onSelect={this.handleSelect}
                              id="controlled-tab-example" className="mb-3">
                            <Tab eventKey="nameplates" title="Tabliczki znamionowe">
                                <MachineNamePlates eventKey='nameplates' idDevice={this.state.id}/>
                            </Tab>
                            <Tab eventKey="notes" title="Notatki">
                                <MachineNotes eventKey='notes' idDevice={this.state.id}/>
                            </Tab>
                            <Tab eventKey="files" title="Pliki">
                                <MachineFiles idDevice={this.state.id}/>

                            </Tab>
                            <Tab eventKey="parameters" title="Parametry">
                                <MachineParameters idDevice={this.state.id}/>
                            </Tab>
                            <Tab eventKey="equipments" title="Części">
                                <MachineEquipments
                                    idDevice={this.state.id}/>
                            </Tab>
                            <Tab eventKey="incident" title="Zdarzenia">
                                <MachineIncident
                                    idDevice={this.state.id}/>
                            </Tab>
                            <Tab eventKey="service" title="Serwis">
                                <MachineService data={{
                                    services: this.state.services,
                                    idDevice: this.state.id,
                                    idCustomer: this.state.machine.customer.id
                                }}/>
                            </Tab>
                            <Tab eventKey="periodicInspection" title="Przeglądy">
                                <MachinePeriodicInspection
                                    machine={this.state.machine}/>
                            </Tab>
                            <Tab eventKey="Map" title="Lokalizacja">
                                <MachineMap device={this.state.machine} ref={this.map}/>
                            </Tab>
                            <Tab eventKey="hotels" title="Hotele">
                                <MachineHotels idDevice={this.state.machine.id} ref={this.hotels}/>
                            </Tab>
                        </Tabs>
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default MachineExternalEdit;
